import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../../utils/AxiosInstance";
import useShowToast from "../hooks/useShowToast";

const ReferralRegister = () => {
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    } = useForm();
    const location = useLocation();
  const navigate = useNavigate();
  const showToast = useShowToast();
  // Phone state
  // Country code and phone state
  const [countryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");

  // const handleFormSubmit = (data) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     // Navigate to success page
  //     navigate(`${process.env.PUBLIC_URL}/auth-success`);
  //   }, 1000);
  // };

  // Extract referral from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const referral = queryParams.get("referral");

  const handleFormSubmit = async (data) => {
    setLoading(true);
    try {
      // Prepare the data for the API call
      const requestData = {
        firstName: data.fname,
        lastName: data.lname,
        email: data.email,
        countryCode: countryCode, // Country code state
        phone: phone, // Phone number state // Get phone number from phone input
        userName: data.username,
        password: data.passcode,
      };

      console.log(requestData);

      // Make the API call using axiosInstance
      const response = await axiosInstance.post( `/auth/referral/signup?referral=${referral}`, requestData);

      // Handle the response
      if (response.data.success) {
        // Navigate to success page
        navigate("/auth-success");
      } else {
        showToast("Signup failed", response.message, "warning");
        console.error("Signup failed:", response);
      }
    } catch (error) {
      showToast("Signup failed", error.response.data.message, "warning");
      console.error(" Error during signup:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Head title="Register" />
      <Block className="nk-block-middle nk-auth-body wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Register By Referral</BlockTitle>
              <BlockDes>
                <p>Create New Filan Account</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>

          {/* Registration Form */}
          <form className="is-alter formDiv" onSubmit={handleSubmit(handleFormSubmit)}>
            {/* First Name Field */}
            <div className="form-group">
              <label className="form-label" htmlFor="fname">
                First Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="fname"
                  {...register("fname", { required: true })}
                  placeholder="Enter your first name"
                  className="form-control-lg form-control"
                />
                {errors.fname && <p className="invalid">First Name is required</p>}
              </div>
            </div>

            {/* Last Name Field */}
            <div className="form-group">
              <label className="form-label" htmlFor="lname">
                Last Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="lname"
                  {...register("lname", { required: true })}
                  placeholder="Enter your last name"
                  className="form-control-lg form-control"
                />
                {errors.lname && <p className="invalid">Last Name is required</p>}
              </div>
            </div>

            {/* Email Field */}
            <div className="form-group">
              <label className="form-label" htmlFor="email">
                Email Address
              </label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  id="email"
                  {...register("email", { required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your email address"
                />
                {errors.email && <p className="invalid">Email is required</p>}
              </div>
            </div>

            <div className="d-flex flex-wrap justify-content-between">
              {/* Country Code Input */}
              <div className="form-group countryCode">
                <label className="form-label" htmlFor="countryCode">
                  Country Code
                </label>
                <div className="form-control-wrap">
                  <PhoneInput
                    country={"in"}
                    value={countryCode}
                    onChange={setCountryCode}
                    enableSearch={true}
                    inputProps={{
                      name: "countryCode",
                      required: true,
                    }}
                    inputClass="form-control-lg form-control"
                  />
                  {errors.countryCode && <p className="invalid">Country Code is required</p>}
                </div>
              </div>

              {/* Phone Number Input */}
              <div className="form-group phno">
                <label className="form-label" htmlFor="phone">
                  Contact Number
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter your phone number"
                    className="form-control-lg form-control"
                  />
                  {errors.phone && <p className="invalid">Phone number is required</p>}
                </div>
              </div>
            </div>

            {/* Username Field */}
            <div className="form-group">
              <label className="form-label" htmlFor="username">
                Username
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="username"
                  {...register("username", { required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your username"
                />
                {errors.username && <p className="invalid">Username is required</p>}
              </div>
            </div>

            {/* Password Field */}
            <div className="form-group">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("passcode", { required: "This field is required" })}
                  placeholder="Enter your password"
                  className="form-control-lg form-control"
                />
                {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
              </div>
            </div>

            {/* Submit Button */}
            <div className="form-group">
              <Button type="submit" color="primary" size="lg" className="btn-block">
                {loading ? <Spinner size="sm" color="light" /> : "Register"}
              </Button>
            </div>
          </form>

          {/* Link to Sign In */}
          <div className="form-note-s2 text-center pt-4">
            Already have an account?{" "}
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Sign in instead</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};

export default ReferralRegister;
