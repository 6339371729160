// Price types
export const PriceType = {
  FIXED: "0",
  RANGE: "1",
};

// Acural types
export const AcuralType = {
  EVERY_HOUR: "0",
  EVERY_DAY: "1",
  EVERY_WEEK: "2",
  EVERY_MONTH: "3",
  EVERY_YEAR: "4",
};

// Return types
export const ReturnType = {
  LIFE_TIME: "0",
  PERIOD: "1",
};

export const PurchasedPlanHistoryStatusEnum = {
  ACTIVE: "0",
  COMPLETED: "1",
  CANCELLED: "2",
};
