import React, { useContext, useEffect, useState } from 'react'
import Head from '../../../layout/head/Head'
import Content from '../../../layout/content/Content'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard, UserAvatar } from '../../../components/Component'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import { findUpper } from '../../../utils/Utils'
import { AuthContext } from '../../../context/AuthContext'
import axiosInstance from '../../../utils/AxiosInstance'

function RefferBonus() {
  const { userInfo } = useContext(AuthContext); // Get userInfo from AuthContext
  const [referredUsers, setReferredUsers] = useState([]); // State to store referred users list
  // Fetch referral user list from API
  const fetchReferredUsers = async () => {
    try {
      const response = await axiosInstance.get("/user/referral/user-list", {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Pass token in the request headers
        },
      });

      // Update state with the referred user list
      if (response.data && response.data.success) {
        setReferredUsers(response.data.data.referredUserList);
      }
    } catch (error) {
      console.error("Error fetching referred users:", error);
    }
  };

  // Call API on component mount
  useEffect(() => {
    fetchReferredUsers();
  }, []);
  return (
    <>
      <Head title="Refferal bonus"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-white" page>Referred List</BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>Review your current bonuses.</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row>
          {referredUsers.length > 0 ? (
            referredUsers.map((user) => (
              <Col key={user._id} md="6" lg="4" className="mb-4">
                <PreviewAltCard>
                  <div className="team">
                    <div className="user-card user-card-s2">
                      <UserAvatar
                        theme="primary"
                        className="lg"
                        text={user.firstName.charAt(0) + user.lastName.charAt(0)}
                      >
                        <div className="status dot dot-lg dot-success"></div>
                      </UserAvatar>
                      <div className="user-info">
                        <h6 className="text-white">{`${user.firstName} ${user.lastName}`}</h6>
                      </div>
                    </div>
                    <ul className="team-info">
                      <li className="h4">
                        <span className="text-white">Joined Date</span>
                        <span className="text-white">{new Date(user.createdAt).toLocaleDateString()}</span>
                      </li>
                      <li className="h4">
                        <span className="text-white">Contact</span>
                        <span className="text-white">{`${user.countryCode} ${user.phone}`}</span>
                      </li>
                      <li className="h4">
                        <span className="text-white">Email</span>
                        <span className="text-white">{user.email}</span>
                      </li>
                      <li className="h4">
                        <span className="text-white">ID</span>
                        <span className="text-white">{user.ID}</span>
                      </li>
                      {/* <li className="h4">
                        <span>KYC Status</span>
                        <span>{user.kycStatus === "4" ? "Verified" : "Not Verified"}</span>
                      </li> */}
                    </ul>
                  </div>
                </PreviewAltCard>
              </Col>
            ))
          ) : (
            <p className='text-white'>No referred users found.</p>
          )}
        </Row>
      </Content>
    </>
  );
}

export default RefferBonus